<template>
  <div>
    <vs-checkbox class="parent" :vs-value="'all'">All</vs-checkbox>
    <vs-checkbox
      class="child"
      v-for="(spoc, index) in this.tree"
      :key="index"
      v-model="selectedSpoc"
      :vs-value="spoc.title"
      >{{ spoc.title }}</vs-checkbox
    >
  </div>
</template>

<script>
export default {
  props: ["tree"],
  mounted() {
    this.tree[3].children = ["3", "4", "5"];
    console.log(this.tree);
  },
  data() {
    return {
      selectedSpoc: []
    };
  }
};
</script>

<style>
.child {
  margin-left: 10%;
}
</style>
