<template>
  <div>
    <vs-table
      multiple
      v-model="selected"
      max-items="50"
      :data="$store.state.MwbLeads"
    >
      <template slot="thead">
        <vs-th>
           Can-ID
        </vs-th>
        <vs-th>Name</vs-th>
        <vs-th style="width: 10%">Company</vs-th>
         <vs-th>Education</vs-th>
        <vs-th>Exp</vs-th>
        <vs-th style="width: 20%">Comments</vs-th>   
        <vs-th>USP Level</vs-th>
        <vs-th>Level</vs-th>
        <vs-th>USP SPOC</vs-th>
        <vs-th v-if="getSubTeam('INT')">City</vs-th>
        <vs-th v-if="getSubTeam('INT')">Country</vs-th>
        <vs-th>SPOC</vs-th>
        <vs-th>SR SPOC</vs-th>
        <vs-th>First Source </vs-th>
        <vs-th>Last Source </vs-th>
        <vs-th>MHP Date</vs-th>
        <vs-th>Scheduled Date</vs-th>
        <vs-th v-if='getTeam("CM") || (!getTeam("SR") && !getSubTeam("MAcc"))'>Last Call</vs-th>
        <vs-th v-if='getTeam("CM") || (!getTeam("SR") &&  !getSubTeam("MAcc"))'>Next Call</vs-th>
        <vs-th v-if='getTeam("SR") || (!getTeam("CM") && !getSubTeam("MAcc"))'>SR Last Call</vs-th>
        <vs-th v-if='getTeam("SR") || (!getTeam("CM") && !getSubTeam("MAcc"))'>SR Next Call</vs-th>
        <!-- <vs-th v-if='(getTeam("ExEd") && getSubTeam("MAcc")) || (!getTeam("CM") && !getTeam("SR"))'>IIML Last Call</vs-th>
        <vs-th v-if="(getTeam('ExEd') && getSubTeam('MAcc')) || (!getTeam('CM') && !getTeam('SR'))">IIML Next Call</vs-th> -->
        <vs-th>View Info</vs-th>
      </template>

      <template slot-scope="{ data }">
        <tbody ref="tbody">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td>
              <div>{{ tr.identity }}</div>
            </vs-td>
            <vs-td :data="tr.email">
              <vs-row>
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-start"
                  vs-w="12"
                >
                  <vx-tooltip
                    :title="tr.spoc_name + ' (' + tr.engagement_added_on + ')'"
                    :text="tr.engagement_details"
                    >{{ tr.person_name }}</vx-tooltip
                  >
                </vs-col>
              </vs-row>
            </vs-td>
            <vs-td>
              <div>{{ tr.company }}</div>
            </vs-td>
            <vs-td>
              <div>{{ tr.education_tags }}</div>
            </vs-td>
            <vs-td>
              <div>{{ tr.experience_int }}</div>
            </vs-td>

            <vs-td>
              <!-- <div>{{ tr.engagement_details}}</div> -->
              <div>{{ tr.engagement_details ? getComment(tr.engagement_details) : '' }}</div>
            </vs-td>

            <!-- <vs-td v-if="!getrole('exed') && !getrole('exed2')">
              <vs-chip :color="getColor(tr.level)">
                <b>{{ tr.level }}</b>
              </vs-chip>
            </vs-td>

            <vs-td v-else>
              <vs-chip :color="getColor(tr.level)">
                <b>{{ tr.iiml_level }}</b>
              </vs-chip>
            </vs-td>-->
          
            <vs-td>
              <vx-tooltip :text="tr.iiml_level">
                <vs-chip :color="'primary'">
                  <b>{{ getIIMLText(tr.iiml_level) }}</b>
                </vs-chip>
              </vx-tooltip>
            </vs-td>

            <vs-td>
              <vs-chip :color="getColor(tr.level)">
                <b>{{ tr.level }}</b>
              </vs-chip>
            </vs-td>

            <vs-td>
              <div>{{ tr.assigned_iiml_name }}</div>
            </vs-td>

            <vs-td v-if="getSubTeam('INT')">
              <div>{{ tr.international_city }}</div>
            </vs-td>

            <vs-td v-if="getSubTeam('INT')">
              <div>{{ tr.country }}</div>
            </vs-td>

            <vs-td>
              <div>{{ tr.spoc_name }}</div>
            </vs-td>
            <vs-td>
              <div>{{ tr.sr_name }}</div>
            </vs-td>
            <vs-td>
              <div>{{ tr.source }} <span v-if="tr.source != 'N-DB' && tr.source != 'BTL' && tr.source != 'IVR'" >- {{ tr.source_details }}</span></div>
            </vs-td>
            <vs-td>
              <div v-if='tr.last_source == "Webinar"'>{{ tr.net_enquiry_type }}</div>
              <div v-else>{{ tr.last_source }}</div>
            </vs-td>
            <vs-td >
              <div>{{ unixTimestampConvert(tr.mhp_date) }}</div>
            </vs-td>
            <vs-td>
              <div v-if="tr.scheduled_date">{{ unixTimestampConvert(tr.scheduled_date) }}</div>
              <div v-else></div>
            </vs-td>
            <vs-td v-if='getTeam("CM") || (!getTeam("SR") && !getSubTeam("MAcc"))'>
              <div>{{ unixTimestampConvert(tr.last_call) }}</div>
            </vs-td>
            <vs-td v-if='getTeam("CM") || (!getTeam("SR") && !getSubTeam("MAcc"))'>
              <div>{{ unixTimestampConvert(tr.next_call) }}</div>
            </vs-td>
            <vs-td v-if='getTeam("SR") || (!getTeam("CM") && !getSubTeam("MAcc"))'>
              <div>{{ unixTimestampConvert(tr.sr_last_call) }}</div>
            </vs-td>
            <vs-td v-if='getTeam("SR") || (!getTeam("CM") && !getSubTeam("MAcc"))'>
              <div>{{ unixTimestampConvert(tr.sr_next_call) }}</div>
            </vs-td>
            <!-- <vs-td v-if='(getTeam("ExEd") && getSubTeam("MAcc")) || (!getTeam("CM") && !getTeam("SR"))'>
              <div>{{ unixTimestampConvert(tr.iiml_last_call) }}</div>
            </vs-td>
            <vs-td v-if="(getTeam('ExEd') && getSubTeam('MAcc')) || (!getTeam('CM') && !getTeam('SR'))">
              <div>{{ unixTimestampConvert(tr.iiml_next_call) }}</div>
            </vs-td> -->
            <vs-td>
              <vs-row>
                <div @click="openPopupEmit(tr)">
                  <vs-icon
                    icon="info_outline"
                    size="small"
                    color="dark"
                  ></vs-icon>
                </div>
                <enrollentbutton
                  v-if="getrole('cm1') && !getSubTeam('IIML-SF') || getrole('cm4') || getrole('ac1') || getrole('exed2') || !getSubTeam('IIML-FT') "
                  :lead="tr"
                />
                <IIMLenrollentbutton
                  :lead="tr"
                ></IIMLenrollentbutton>
                <!-- <enrollentbutton :lead="tr" v-if="tr.enrollment_added === 0" />
                <div v-if="tr.enrollment_added === 1">
                  <vs-icon
                    icon="check_circle_outline"
                    size="small"
                    color="dark"
                  ></vs-icon>
                </div>-->
              </vs-row>
              <vs-row>
                <!-- <div @click="IIMLEnrollmentPopup(tr)">
                  <vs-icon
                    icon="check_circle_outline"
                    size="small"
                    color="dark"
                  ></vs-icon>
                </div>-->
                <!-- <div @click="IIMLEnrollmentPopup(tr)">
                  <vs-icon icon="check_circle_outline" size="small" color="dark"></vs-icon>
                </div>-->
                <!-- <enrollentbutton v-if="!checkIfRoleis('exed')" :lead="tr" /> -->
                <!-- <enrollentbutton :lead="tr" v-if="tr.enrollment_added === 0" />
                <div v-if="tr.enrollment_added === 1">
                  <vs-icon
                    icon="check_circle_outline"
                    size="small"
                    color="dark"
                  ></vs-icon>
                </div>-->
              </vs-row>
            </vs-td>
          </vs-tr>
        </tbody>
      </template>
    </vs-table>
  </div>
</template>

<script>
import Vue from "vue";
import EventBus from "../eventbus.js";
// import axios from "axios";
// import moment from "moment";
import enrollentbutton from "./buttons/AddEnrollmentButton.vue";
import IIMLenrollentbutton from "./buttons/AddIIMLEnrollmentButton.vue";
// import constants from "../../../constants.json";
// import tooltipInfo from "./TooltipIIMLLevels.vue";

Vue.config.productionTip = false;
export default {
  components: {
    enrollentbutton,
    IIMLenrollentbutton,
    // tooltipInfo
  },
  props: ["totalpagination"],
  data() {
    return {
      IIMLLevelsTooltip:
        // "VH - Very Hot, AR - Application Received, EP - Exam-Pass, EF - Exam-Fail, EPD - Exam-Pass-Dropout, END - Exam-Noshow-Dropout, CMYTC - CM-Yet-To-Connect",
        "VH - Very Hot, AR - Application Received, EP - Exam-Pass, EF - Exam-Fail, EPD - Exam-Pass-Dropout, END - Exam-Noshow-Dropout",
      selectall: false,
      expand: false,
      selectedleads: [],
      notselectedleads: [],
      tablelinks: 0,
      currentTablePage: 1,
      selected: [],
      tableList: [
        "vs-th: Component",
        "vs-tr: Component",
        "vs-td: Component",
        "thread: Slot",
        "tbody: Slot",
        "header: Slot",
      ],
    };
  },
  mounted() {
    console.log('object--',this.$store.state.MwbLeads);
    document
      .getElementsByClassName("vs-table--thead")[0]
      .addEventListener("click", this.selectallFunc, false);
    EventBus.$on("open-email-bdeleads", () => {
      if (this.selectall === false) {
        EventBus.$emit("open-email-engagement", this.selected);
      } else if (this.selectall == true) {
        EventBus.$emit("open-email-leadsdatatable", this.notselectedleads);
      }
    });
  },
  watch: {
    selected: function (value) {
      this.notselectedleads = [];
      this.$store.state.MwbLeads.forEach((lead) => {
        if (value.indexOf(lead) === -1) {
          this.notselectedleads.push(lead);
        }
      });
    },
    currentTablePage: function (val) {
      this.getMwbLeads(val);
    },
  },
  methods: {
    // IIMLEnrollmentPopup(tr) {
    //   EventBus.$emit("open-IIMLEnrollmentPopup", tr);
    // },
    getIIMLText(level) {
      let displayLevel = "";
      switch (level) {
        case "Very Hot":
          displayLevel = "VH";
          break;
        case "Application Received":
          displayLevel = "AR";
          break;
        case "Exam-Pass":
          displayLevel = "EP";
          break;
        case "Exam-Fail":
          displayLevel = "EF";
          break;
        case "Exam-Pass-Dropout":
          displayLevel = "EPD";
          break;
        case "Exam-Fail-Dropout":
          displayLevel = "EFD";
          break;
        case "Exam-Noshow-Dropout":
          displayLevel = "END";
          break;
        // case "CM-Yet-To-Connect":
        //   displayLevel = "CMYTC";
        //   break;
        default:
          displayLevel = level;
      }
      return displayLevel;
    },
    getlastCall(tr) {
      if (this.$route.path === "/myLeads") {
        return tr.last_call;
      } else if (this.$route.path === "/IIMLBussinessLeads") {
        return tr.iiml_last_call;
      } else {
        return tr.last_call;
      }
    },
    getComment(comment){
      if(comment.length > 100){
        const details = comment.slice(0, 100) + "..."
        return details
      }else{
        return comment
      }
    },
    getnextCall(tr) {
      if (this.$route.path === "/myLeads") {
        return tr.next_call;
      } else if (this.$route.path === "/IIMLBussinessLeads") {
        return tr.iiml_next_call;
      } else {
        return tr.next_call;
      }
    },
    sortTable(value) {
      console.log(value);
    },
    selectallFunc() {
      this.selectall = !this.selectall;
    },
    // unixTimestampConvert(value) {
    //   var dateString = moment.unix(value).format("DD/MM/YYYY");
    //   switch (dateString) {
    //     case "01/01/1970":
    //       return "";
    //     default:
    //       return dateString;
    //   }
    // },
    additional(emit, data) {
      this.openPopupEmit(data, emit);
      // EventBus.$emit("select-call-section");
    },
    /* openPopupEmit(payload, emit) {
      let courses = this.getEnrolledCourses(payload);
      if (payload.level == "M7" && payload.city == "Hyderabad") {
        if (payload.courses.indexOf("CPA") != -1) {
          this.openCPAPopup(payload);
          return;
        } else if (payload.courses.indexOf("CMA") != -1) {
          this.openCMAPopupFromMwb(payload);
          return;
        }
      }
      this.openMWBPopup(payload);
    }, */
    openPopupEmit(payload) {
      this.openBigPopup(payload.id);
      // console.log("my data: ", payload);
      // let courses = this.getEnrolledCourses(payload);
      // console.log(courses);
      // if (payload.level == "M7" && payload.city == "Hyderabad") {
      //   if (payload.courses.indexOf("CPA") != -1) {
      //     this.openCPAPopup(payload);
      //     return;
      //   } else if (payload.courses.indexOf("CMA") != -1) {
      //     this.openCMAPopupFromMwb(payload);
      //     return;
      //   }
      // }
      // this.openMWBPopup(payload);
    },
    IIMLEnrollmentPopup(tr) {
      EventBus.$emit("open-IIMLEnrollmentPopup", tr);
    },
    getEnrolledCourses(details) {
      let arr = [];
      details.enrollments.forEach((enrollment) => {
        arr.push(enrollment.course);
      });
      return arr;
    },
    addengagement(payload) {
      console.log("emitting");
      EventBus.$emit("open-enrollment-popup", payload);
    },
    fetchbdeName(bdeid) {
      let name = "";
      this.$store.state.bdes.forEach((bde) => {
        if (bde.id === bdeid) {
          name = bde.bdename;
        }
      });
      return name;
    },
    EmitEvent(emit, payload) {
      if (payload === "") {
        EventBus.$emit(emit);
      } else {
        EventBus.$emit(emit, payload);
      }
    },
    fetchEngagementDate(latest_engagement) {
      let date = "";
      this.$store.state.engagements.forEach((engagement) => {
        if (engagement.engagement === latest_engagement) {
          date = engagement.engagement_date;
        }
      });
      return date;
    },
    getColor(level) {
      switch (level) {
        case "M1":
          return "danger";
        case "M2":
          return "danger";
        case "M3":
          return "success";
        case "M3+":
          return "success";
        case "M3++":
          return "success";
        case "M4-":
          return "danger";
        case "M4":
          return "danger";
        case "M5":
          return "success";
        case "M6":
          return "success";
        case "L1":
          return "danger";
        case "L2":
          return "warning";
        case "L3":
          return "warning";
        case "L3+":
          return "success";
        case "L4-":
          return "danger";
        case "L4":
          return "warning";
        case "L5":
          return "success";
        case "L6":
          return "success";
        default:
          return "primary";
      }
    },
    engagementlast(engagements) {
      return engagements.slice(-1)[0];
    },
  },
};
</script>

<style></style>
