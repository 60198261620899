<template>
  <vs-navbar class="vx-navbar navbar-custom" color="white">
    <vs-row>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3">
          <b style="color: gray">{{ selected }}</b> 
        </vs-col>
         <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="9">
           <vs-button :color="'dark'" text type="line" @click="sortEmit('all')">All</vs-button>
          <vs-button :color="'dark'" text type="line" @click="sortEmit('leads')">Leads</vs-button>
          <vs-button :color="'dark'" text type="line" @click="sortEmit('opportunities')">Opportunities</vs-button>
          <vs-button :color="'dark'" text type="line" @click="sortEmit('accounts')">Account</vs-button>
        </vs-col>
    </vs-row>
  </vs-navbar>
</template>

<script>
export default {
  data() {
    return {
      selected: 'ALL'
    }
  },
  methods: {
    sortEmit(payload) {
      this.selected = payload.toUpperCase()
      this.$store.commit('SORT_TABLE', payload)
    }
  }
}
</script>

<style>

</style>
