<template>
  <div @click="openEnrollmentForm()">
    <vs-icon icon="add_circle" size="small" color="dark"></vs-icon>
  </div>
  <!-- <img src="https://img.icons8.com/windows/24/000000/plus.png"/> -->
</template>

<script>
import EventBus from "../../eventbus.js";
import axios from "axios";
import constants from "../../../../constants.json";
export default {
  props: {
    lead: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      levels_list: ['M7', 'M8', 'M9', 'M10', 'M7-', 'M8-', 'M9-', 'M7+', 'M7X', 'M7#', 'M7JJ', 'M8+', 'M7D1', 'M7D2'],
    };
  },
  methods: {
    openEnrollmentForm() {
      if (!this.levels_list.includes(this.lead.level)) {
        this.showCustomNotification(
          "Not Valid",
          "The lead's level must be M7 or above M7 to start the enrollment process",
          "danger"
        );
        // this.$vs.loading.close();
        return;
      }

      this.$vs.loading();

      let getParams = {
        mwb_id: this.lead.id,
      };
      let url = `${constants.SERVER_API}getCandidateDetails`;
      axios
        .get(url, {
          params: getParams,
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          if (response.data.status !== "success") {
            this.handleNotification(response);
          } else {
            this.validateLead(response.data.data);
          }
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.handleError(error);
          this.$vs.loading.close();
        });
      // let url = `${constants.SERVER_API}getSingleMwbLead?can_id=${this.lead.can_id}&person_id=${this.lead.person_id}`;
      // axios
      //   .get(url, {
      //     headers: { Authorization: `Bearer ${localStorage.userAccessToken}` }
      //   })
      //   .then(response => {
      //     this.$store.commit("UPDATE_NEW_DATA", [
      //       this.lead,
      //       response.data.engagements,
      //       response.data.mobile,
      //       response.data.emails
      //     ]);
      //     this.fetchRequiredData(this.lead);
      //   })
      //   .catch(error => {
      //     this.handleError(error);
      //   });
    },

    validateLead(leadData) {
      // console.log(leadData);
      if (leadData.emails.length === 0 || leadData.emails.length === null) {
        this.showCustomNotification(
          "Not Valid",
          "Requires atleast one email to start the enrollment process",
          "danger"
        );
        return;
      } else if (leadData.mwb.company === "" || leadData.mwb.company === null) {
        this.showCustomNotification(
          "Not Valid",
          "Requires the company field to be filled to start the enrollment process",
          "danger"
        );
        return;
      } else if (leadData.mwb.designation === "" || leadData.mwb.designation === null) {
        this.showCustomNotification(
          "Not Valid",
          "Requires the designation field to be filled to start the enrollment process",
          "danger"
        );
        return;
      } else if (
        leadData.mwb.experience_int === "" ||
        leadData.mwb.experience_int === null
      ) {
        this.showCustomNotification(
          "Not Valid",
          "Requires the experience field to be filled to start the enrollment process",
          "danger"
        );
        return;
      } 
      // else if (
      //   leadData.mwb.education_tags === "" ||
      //   leadData.mwb.education_tags === null
      // ) {
      //   this.showCustomNotification(
      //     "Not Valid",
      //     "Requires atleast one education tag to start the enrollment process",
      //     "danger"
      //   );
      //   return;
      // } 
      else if (!this.primaryExists(leadData.emails)) {
        this.showCustomNotification(
          "Not Valid",
          "Primary email is required to start the enrollment process",
          "danger"
        );
        return;
      } else if (!this.primaryExists(leadData.mobiles)) {
        this.showCustomNotification(
          "Not Valid",
          "Primary mobile number is required to start the enrollment process",
          "danger"
        );
        return;
      }
      let mobileString = this.fetchMobileNumbers(leadData);
      let emailString = this.fetchEmailIds(leadData);
      leadData.mwb.emailString = emailString;
      leadData.mwb.mobileString = mobileString;
      // console.log(emailString);
      // console.log(mobileString);
      // leadData.mwb.enrollments = leadData.enrollments;
      leadData.mwb.payloadEmails = leadData.emails;
      EventBus.$emit("open-enrollment-popup", leadData.mwb);
      // console.log("Next validation");
    },

    primaryExists(arrayForValidation) {
      let bool = false;
      arrayForValidation.forEach((item) => {
        if (item.pri_mary === 1) {
          bool = true;
        }
      });
      return bool;
    },

    async fetchRequiredData(payload) {
      let mobileString = await this.fetchMobileNumbers(payload);
      let emailString = await this.fetchEmailIds(payload);
      if (emailString === "") {
        this.$vs.notify({
          title: "Not Valid",
          text: "Requires atleast one email to start the enrollment process",
          color: "danger",
        });
      } else {
        payload.emailString = emailString;
        payload.mobileString = mobileString;
        EventBus.$emit("open-enrollment-popup", payload);
      }
      this.$vs.loading.close();
    },

    fetchMobileNumbers(payload) {
      let concatString = [];
      if (payload.mobiles.length === 0) {
        let empty = "";
        return empty;
      } else {
        payload.mobiles.forEach((mobile) => {
          if (mobile.pri_mary === 1) {
            concatString.push(mobile.last_ten_digits);
          }
        });
        concatString = concatString.join();
        return concatString;
      }
    },

    fetchEmailIds(payload) {
      let concatString = [];
      if (payload.emails.length === 0) {
        let empty = "";
        return empty;
      } else {
        payload.emails.forEach((email) => {
          if (email.pri_mary === 1) {
            concatString.push(email.email);
          }
        });
        concatString = concatString.join();
        return concatString;
      }
    },
  },
};
</script>

<style></style>
