<template>
  <div>
    <!-- <div>
      SEARCH
    </div>-->
    <ul class="leftx">
      <!-- <li class="modelx li-gap">
        {{radios1}}
      </li>-->
      <li class="li-gap">
        <vs-checkbox v-model="radios1" vs-value="delays">Delays</vs-checkbox>
      </li>
      <li class="li-gap">
        <vs-checkbox v-model="radios1" vs-value="today">Today</vs-checkbox>
      </li>
      <li class="li-gap">
        <vs-checkbox v-model="radios1" vs-value="waiting">Waiting</vs-checkbox>
      </li>
      <li class="li-gap">
        <vs-checkbox v-model="radios1" vs-value="untapped">Untapped</vs-checkbox>
      </li>
    </ul>
  </div>
</template>

<script>
import EventBus from "../eventbus.js";
export default {
  data() {
    return {
      radios1: [],
    };
  },
  mounted() {
    EventBus.$on("refresh-focus", () => {
      // console.log("got the event");
      this.radios1 = "";
      EventBus.$emit("radio-change", this.radios1);
    });
  },
  watch: {
    radios1: function (value) {
      EventBus.$emit("radio-change", value);
    },
  },
};
</script>

<style>
.li-gap {
  margin-bottom: 3%;
}
</style>
