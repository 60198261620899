<template>
  <!-- <div class="mainCard">
    <diV style="margin: 1%">
      <div class="row">
        <div class="ColumnFifty">
          <h2 class="h2Text" style="margin-top: 1%">
            M-23498 - Neha Kapoor - TCS
          </h2>
        </div>
        <div class="ColumnFifty">
          <vs-button color="dark" type="flat">Open Info</vs-button>
          <vs-button color="dark" type="flat">Add Enrollment</vs-button>
        </div>
      </div>
      <div class="row">
        <div class="column">
          <p class="paragraphText">CPA - M3+</p>
          <p class="paragraphText">CMA - M3+</p>
          <p class="paragraphText">IIML - COLD</p>
        </div>
        <div class="column">
          <p class="paragraphText">Next Call - 29-Nov-2021</p>
          <p class="paragraphText">Last Call - 19-Nov-2021</p>
          <p class="paragraphText">Spoc - Imran</p>
        </div>
        <div class="column">
          <p class="paragraphText">
            Busy in work will call back later/Gave info about the course and
            advised to attend webinar/Need to call tomorrow 1.30/He has an
            operation on 9th december so IIML not possible but he will join CMA
            from 22nd december
          </p>
        </div>
      </div>
    </diV>
  </div> -->
  <vs-card>
    <div slot="header">
      <vs-row vs-w="12">
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="flex-start"
          vs-w="6"
          ><h3 class="h2Text" style="margin-top: 1%; font-family: sans-serif;">
            M-23498 - Neha Kapoor - TCS
          </h3></vs-col
        >
        <vs-col
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="flex-start"
          vs-w="6"
        >
          <vs-button
            color="dark"
            type="border"
            size="small"
            style="margin-right: 1%"
            @click="openMwb"
            >Open Info</vs-button
          >
          <vs-button color="dark" type="border" size="small"
            >Add Enrollment</vs-button
          >
        </vs-col>
      </vs-row>
    </div>
    <div>
      <vs-row vs-w="12" style="margin-bottom:1%">
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="flex-start"
          vs-w="2"
        >
          <ul class="nav">
            <li><b>CPA - M3+</b></li>
            <li><b>CMA - M3+</b></li>
            <li><b>IIML - COLD</b></li>
          </ul>
          <!-- <ul class="nav">
            <li><b>CPA - M3+</b></li>
            <li><b>CMA - M3+</b></li>
            <li><b>IIML - COLD</b></li>
          </ul> -->
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="flex-start"
          vs-w="6"
        >
          <ul class="nav" style="margin-right: 4%">
            <li><b>CM Next Call - 29-Nov-2021</b></li>
            <li><b>CM Last Call - 31-Jan-2021</b></li>
            <li><b>CM Spoc - Imran</b></li>
          </ul>

          <ul class="nav">
            <li><b>IIML Next Call - 29-Nov-2021</b></li>
            <li><b>IIML Last Call - 31-Jan-2021</b></li>
            <li><b>IIML Spoc - Ismail</b></li>
          </ul>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="flex-end"
          vs-w="4"
        >
          Busy in work will call back later/Gave info about the course and
          advised to attend webinar/Need to call tomorrow 1.30/He has an
          operation on 9th december so IIML not possible but he will join CMA
          from 22nd december
        </vs-col>
      </vs-row>
    </div>
    <!-- <div slot="footer">
      <vs-row vs-justify="flex-end">
        <vs-button type="gradient" color="danger" icon="favorite"></vs-button>
        <vs-button color="primary" icon="turned_in_not"></vs-button>
      </vs-row>
    </div> -->
  </vs-card>
</template>

<script>
export default {
  methods: {
    openMwb() {
      this.openBigPopup(96422);
    }
  }
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css?family=Lato:400,700");
$font: "Lato", sans-serif;

$white: #f5f5f5;
$light: #e0c9cb;
$tan: #c3a1a0;
$pink: #d9aab7;
$rose: #ba7e7e;
$dark: #4e4e4e;

.mainCard {
  position: relative;
  margin: auto;
  overflow: hidden;
  width: 100%;
  height: fit-content;
  // background: $white;
  box-shadow: 5px 5px 15px rgba($dark, 0.2);
  border-radius: 10px;
}

.paragraphText {
  font-size: 1em;
  margin-top: 5%;
  color: $rose;
  letter-spacing: 1px;
}

.h1Text {
  font-size: 1.2em;
  color: $dark;
  margin-top: -5px;
}

.h2Text {
  color: $dark;
  margin-top: -5px;
}

.row:after {
  content: "";
  display: table;
  clear: both;
}

.column {
  float: left;
  width: 33.33%;
}

.ColumnFifty {
  float: left;
}
</style>
